<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <div @click="$oucy.back()" class="m-b-20 pointer">&lt;返回</div>
            <!-- <h2 class="zdtitle">账单明细</h2> -->
            <el-button-group>
                <!-- 佣金类型[1002:实名认证, 1003:发布求购, 1004:发布商品, 1005:发布直播, 1006:发布企业名片, 1007:发布个人名片, 1008:商品交易, 1009:求购交易, 1010:企业入驻, 1011:买家认证, 1012:企业升级vip, 1013:企业缴纳保证金] -->
                <el-button :type="commissionType==null?'primary':''" @click="setType(null)" size="mini">全部</el-button>
                <el-button :type="commissionType=='1002'?'primary':''" @click="setType('1002')" size="mini">实名认证</el-button>
                <el-button :type="commissionType=='1006'?'primary':''" @click="setType('1006')" size="mini">发布企业名片</el-button>
                <el-button :type="commissionType=='1007'?'primary':''" @click="setType('1007')" size="mini">发布个人名片</el-button>
                <el-button :type="commissionType=='1010'?'primary':''" @click="setType('1010')" size="mini">企业入驻</el-button>
                <el-button :type="commissionType=='1012'?'primary':''" @click="setType('1012')" size="mini">企业升级VIP</el-button>
                <el-button :type="commissionType=='1013'?'primary':''" @click="setType('1013')" size="mini">企业缴纳保证金</el-button>
            </el-button-group>
            <el-table :data="prlductData.content" style="width: 100%" class="m-t-10" v-if="prlductData.content">
                <el-table-column prop="date" label="分销用户" width="150px">
                    <div slot-scope="scope" v-if="scope.row.offUser" class="df-ai-c">
                        <img :src="$oucy.ossUrl+scope.row.offUser.detailAvatar" class="head" alt="">
                        <div class="c-2 m-l-5">{{scope.row.offUser.detailNickname}}</div>
                    </div>
                    <div v-else>平台</div>
                </el-table-column>
                <el-table-column prop="referees" label="推荐人"></el-table-column>
                <el-table-column prop="commissionShow" label="佣金来源"></el-table-column>
                <el-table-column prop="id" label="来源相关信息" width="200px">
                    <div slot-scope="scope" class="df-ai-c">
                        <img :src="$oucy.ossUrl+scope.row.hade" class="head" alt="" v-if="scope.row.hade">
                        <div>
                            <div class="c-2 m-l-5">{{scope.row.naem}}</div>
                            <div class="c-2 m-l-5">{{scope.row.Phone}}</div>
                        </div>
                    </div>
                </el-table-column>
                <el-table-column prop="level" label="佣金级别"></el-table-column>
                <el-table-column prop="id" label="佣金">
                    <div slot-scope="scope" class="df-ai-c">
                        ￥{{scope.row.commissionMoney}}
                    </div>
                </el-table-column>
                <el-table-column prop="id" label="结算状态">
                    <div slot-scope="scope" class="df-ai-c" :class="[scope.row.commissionSettlement?'c-67973D':'c-2090FF']">
                        {{scope.row.commissionSettlement?'已':'待'}}结算
                    </div>
                </el-table-column>
                <el-table-column prop="commissionCreateTime" label="分销时间" width="160px"></el-table-column>
            </el-table>
            <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { financial } from "@/service"
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            commissionType: null,
            prlductData: {},
            limit: 10,
            start: 0,
        }
    },
    mounted() {
        this.getCommissionList()
    },
    methods: {
        setType(v) {
            if (this.commissionType == v) return
            this.commissionType = v
            this.prlductData = {}
            this.limit = 10
            this.start = 0
            this.getCommissionList(v)

        },

        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.getCommissionList();
            this.getCommissionList()
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.getCommissionList();
            this.getCommissionList()
        },

        // 明细
        getCommissionList() {
            financial.getCommissionList({
                limit: this.limit,
                start: this.start,
                commissionType: this.commissionType
            }).then(res => {
                if (res && res.content) {
                    for (let v of res.content) {

                        if (v.commissionType == '1002') {
                            if (v.realName) {
                                v.hade = v.realName.detailAvatar
                                v.naem = v.realName.detailNickname
                                v.Phone = v.realName.userPhoneNumber
                            }
                        } else
                        if (v.commissionType == '1006') {
                            if (v.cardEnterpriseBasic) {
                                v.hade = v.cardEnterpriseBasic.enterpriseAvata
                                v.naem = v.cardEnterpriseBasic.enterpriseName
                                v.Phone = v.cardEnterpriseBasic.cardPhone || '-'
                            }
                        } else
                        if (v.commissionType == '1007') {
                            if (v.cardPersonBasic) {
                                v.hade = v.cardPersonBasic.cardPic
                                v.naem = v.cardPersonBasic.cardName
                                v.Phone = v.cardPersonBasic.cardPhone || '-'
                            }
                        } else
                        if (v.commissionType == '1010') {
                            if (v.enterpriseInfoBasic) {
                                v.hade = v.enterpriseInfoBasic.enterpriseAvata
                                v.naem = v.enterpriseInfoBasic.enterpriseName
                                v.Phone = v.enterpriseInfoBasic.enterprisePhone || '-'
                            }
                        } else
                        if (v.commissionType == '1012') {
                            if (v.enterpriseShopLevelVip) {
                                v.hade = v.enterpriseShopLevelVip.enterpriseAvata
                                v.naem = v.enterpriseShopLevelVip.enterpriseShopName
                                v.Phone = v.enterpriseShopLevelVip.enterprisePhone || '-'
                            }
                        } else
                        if (v.commissionType == '1013') {
                            if (v.enterpriseShopLevelMargin) {
                                v.hade = v.enterpriseShopLevelMargin.enterpriseAvata
                                v.naem = v.enterpriseShopLevelMargin.enterpriseShopName
                                v.Phone = v.enterpriseShopLevelMargin.enterprisePhone || '-'
                            }
                        }

                    }

                }
                this.prlductData = res
            })
        },

    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}

.head {
    width: 32px;
    height: 32px;
    border-radius: 32px;
}
.c-67973D{
    color: #67973D;
}.c-2090FF{
    color: #2090FF;
}
</style>